<template>
  <div class="advanced-table">
    <TableFilter @reset="clearFilters" @apply="applyFilters" :badgeCount="filterCount">
      <template slot="body" class="body">
        <p class="filter-header">Filter Trivia & Quizzes:</p>
        <h3 class="input-header">Type</h3>
        <div class="types">
          <PassportCheckbox v-model="filters.types.trivia" label="Trivia" />
          <PassportCheckbox v-model="filters.types.personality" label="Personality Quiz" />
        </div>
      </template>
    </TableFilter>
    <DataTable
      :tableData="filteredQuizzes"
      :headers="headers"
      :loading="showSkeleton"
      :heading="heading"
      :perPage="10"
      :selectedRows.sync="selectedRows"
      ref="dataTable"
      searchable
    >
      <template v-slot:expand="props">
        <DataTable
          :tableData="props.row.associatedTargets"
          :headers="nestedHeaders"
          :loading="showSkeleton"
          :emptyText="'No targets created yet. Get started by creating a target.'"
        >
          <template v-slot:type>
            <PassportTableColumn width="500px" label="Target Name">
              <template v-slot="props">
                <div class="action-row">
                  <router-link class="target-link" :to="`/edit-target/${props.row.id}`">{{
                    props.row.target.name
                  }}</router-link>
                </div>
              </template>
            </PassportTableColumn>
          </template>
          <template v-slot:qrCodes>
            <PassportTableColumn width="300px" label="QR Codes">
              <template v-slot="props">
                <div class="action-row">
                  <DownloadIcon @click="downloadQRCode(props.row.id)" class="download" />
                </div>
              </template>
            </PassportTableColumn>
          </template>
          <template v-slot:columnsAfter>
            <PassportTableColumn width="205px" label="Actions">
              <template v-slot="props">
                <div class="action-row">
                  <PassportButton
                    class="text--table-action"
                    label="Edit"
                    :to="`/edit-target/${props.row.id}`"
                    variant="text"
                  />
                </div>
              </template>
            </PassportTableColumn>
          </template>
        </DataTable>
      </template>
      <template v-slot:name="n"
        ><router-link
          class="target-link"
          :to="`/${n.row.quizType === 'trivia' ? 'edit-trivia' : 'edit-personality-quiz'}/${n.row.id}`"
          >{{ n.row.name }}</router-link
        ></template
      >
      <template v-slot:quizType="typeProps"
        ><span style="text-transform: capitalize">{{ typeProps.row.quizType }}</span></template
      >
      <template v-slot:instances="i"
        ><div class="instances" @click="handleRowClick(i.row)">{{ i.row.associatedTargets.length }}</div></template
      >
      <template v-slot:columnsAfter>
        <PassportTableColumn width="275px" label="Actions">
          <template v-slot="props">
            <div class="action-row">
              <PassportButton
                class="text--table-action"
                label="Edit"
                :to="`/${props.row.quizType === 'trivia' ? 'edit-trivia' : 'edit-personality-quiz'}/${props.row.id}`"
                variant="text"
              />
              <div class="g-divider vertical" />
              <PassportButton
                class="text--table-action"
                label="Duplicate"
                variant="text"
                @click="duplicateRow(props.row)"
              />
              <div class="g-divider vertical" />
              <PassportButton
                class="text--table-action"
                label="Delete"
                variant="text danger"
                @click="deleteQuiz(props.row)"
              />
            </div>
          </template>
        </PassportTableColumn>
      </template>
    </DataTable>
    <ModalDelete
      ref="deleteModal"
      :deleteCount="selectedRows.length"
      :icon="() => import('@/assets/icons/nav_assets_v2.svg')"
      @cancel="selectedRows = []"
    />
  </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue';
import PassportTableColumn from '@/components/PassportTableColumn.vue';
import PassportButton from '@/components/PassportButton.vue';
import TableFilter from '@/components/TableFilter.vue';
import PassportCheckbox from '@/components/PassportCheckbox.vue';
import ModalDelete from '@/components/ModalDelete.vue';
import { ApiService } from '@/services/api.service';
import DownloadIcon from '@/assets/icons/download_icon.svg';

import QRCodeStyling from 'qr-code-styling';

export default {
  name: 'QuizzesTable',
  components: {
    DataTable,
    PassportTableColumn,
    PassportButton,
    ModalDelete,
    TableFilter,
    PassportCheckbox,
    DownloadIcon,
  },
  props: { heading: String },
  data() {
    return {
      filteredQuizzes: [],
      quizzes: [],
      showSkeleton: true,
      selectedRows: [],
      filters: {
        types: {
          trivia: false,
          personality: false,
        },
      },
      headers: {
        name: {
          label: 'Name',
          sortable: true,
          searchable: true,
          minWidth: '10%',
        },
        quizType: {
          label: 'Type',
          sortable: true,
          searchable: false,
          minWidth: '10%',
        },
        instances: {
          label: 'Instances',
          minWidth: '5%',
          colspan: 2,
        },
        expand: {
          type: 'expand',
          width: '20',
        },
      },
      nestedHeaders: {},
    };
  },
  created() {
    this.getQuizzes().finally(() => {
      this.showSkeleton = false;
    });
  },
  computed: {
    filterCount() {
      let count = 0;
      Object.values(this.filters.types).forEach((type) => {
        if (type) count += 1;
      });
      if (this.filters.selectedGroup) count += 1;
      return count;
    },
  },
  methods: {
    handleRowClick(event) {
      this.$refs.dataTable.expandRow(event);
    },
    downloadQRCode(id) {
      this.qrURL = `${process.env.VUE_APP_URL}/target/${id}`;
      const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        type: 'png',
        data: `${this.qrURL}`,
        backgroundOptions: {
          color: '#ffffff',
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 20,
        },
      });
      qrCode.download({ name: `target-${id}`, extension: 'png' });
    },
    async deleteQuiz(value) {
      if (!(await this.$refs.deleteModal.confirm())) return;
      if (value.quizType === 'trivia') {
        await ApiService.delete(`/quiz/trivia/${value.id}`).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.$notify({
              title: 'Success',
              message: 'Trivia deleted successfully',
              type: 'success',
            });
          }
        });
      } else {
        await ApiService.delete(`/quiz/personality/${value.id}`).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.$notify({
              title: 'Success',
              message: 'Personality quiz deleted successfully',
              type: 'success',
            });
          }
        });
      }
    },
    deleteMultipleQuiz(value) {
      this.selectedRows = [{ ...value, checked: true }];
      this.deleteRows(value.id, value.quizType);
    },
    async duplicateRow(row) {
      // console.log(row);
      // this.quizzes.push(row);
      // if (row.quizType === 'trivia') {
      //   await ApiService.put(`quiz/trivia/${row.id}`).then((response) => {
      //     if (response.status >= 200 && response.status < 300) {
      //       this.$notify({
      //         title: 'Success',
      //         message: 'Trivia duplicated successfully',
      //         type: 'success',
      //       });
      //     }
      //   });
      // } else {
      //   await ApiService.put(`quiz/personality/${row.id}`).then((response) => {
      //     if (response.status >= 200 && response.status < 300) {
      //       this.$notify({
      //         title: 'Success',
      //         message: 'Personality quiz duplicated successfully',
      //         type: 'success',
      //       });
      //     }
      //   });
      // }
      return row;
    },
    applyFilters() {
      if (this.filters.types.trivia) {
        this.filteredQuizzes = this.quizzes.filter((quiz) => quiz.quizType === 'trivia');
      } else if (this.filters.types.personality) {
        this.filteredQuizzes = this.quizzes.filter((quiz) => quiz.quizType === 'personality');
      }
      if (this.filters.types.trivia && this.filters.types.personality) {
        this.filteredQuizzes = this.quizzes;
      }
    },
    clearFilters() {
      Object.keys(this.filters.types).forEach((key) => {
        this.filters.types[key] = false;
      });
      this.filteredQuizzes = this.quizzes;
    },
    async getQuizzes() {
      await ApiService.get('/quiz')
        .then((response) => {
          this.quizzes = response.data.quizzes;
          Object.keys(this.quizzes).forEach((key) => {
            this.quizzes[key].instances = this.quizzes[key].associatedTargets.length;
          });
        })
        .catch((err) => {
          this.$notify({
            group: 'primary',
            type: 'error',
            text: `There was an error getting quizzes: ${err.message}`,
          });
        });
      this.filteredQuizzes = this.quizzes;
    },
  },
};
</script>

<style lang="scss" scoped>
.advanced-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  ::v-deep .el-table__body-wrapper tbody tr.el-table__row div.cell {
    cursor: initial !important;
  }

  .target-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    color: #222;
    text-decoration: none;
  }

  .instances {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    cursor: pointer;
  }

  ::v-deep .filter {
    position: absolute;
    z-index: 7;
  }
  ::v-deep .search {
    margin-left: 9em;
  }
  ::v-deep .passport-button.default.primary {
    display: flex;
    align-items: center;
    height: 3.4em;
    margin-right: 10px;
    font-size: 14px;
    text-transform: uppercase;
  }

  .actions {
    align-self: flex-end;
  }
  .download {
    width: 19px;
  }

  .filter-header {
    font-size: 28px;
  }
  .input-header {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }

  ::v-deep .passport-button.default.danger {
    display: flex;
    align-items: center;
    height: 3.4em;
    margin-left: 2em;
    font-size: 14px;
    text-transform: uppercase;
  }
  .filter {
    h5 {
      margin: 0;
      margin-bottom: 8px;
    }
  }
  .types {
    display: flex;
    flex-direction: column;
    gap: 10px 15px;
    margin-bottom: 20px;
  }
  ::v-deep .filter-modal {
    width: 325px;
  }
  .passport-checkbox {
    flex-shrink: 0;
    color: black;
    ::v-deep {
      .checkbox {
        width: 20px;
        height: 20px;
      }
      .label {
        margin-right: 1em;
        margin-left: 1em;
        font-size: 18px;
        white-space: nowrap;
      }
    }
  }
}
</style>
